<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    :append-to-body="true">
    <el-form v-loading="formLoading" :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
      <el-form-item label="数据源名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="数据源名称" maxlength="100" show-word-limit></el-input>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="数据源IP" prop="dsIp">
            <el-input v-model="dataForm.dsIp" placeholder="数据源IP"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数据源端口" prop="dsPort">
            <el-input v-model="dataForm.dsPort" placeholder="数据源端口"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="数据库类型" prop="dsType">
            <sysDictSelect v-model="dataForm.dsType" dictType="database_type" placeholder="请选择数据库类型" filterable/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数据库名称" prop="dsDatabase">
            <el-input v-model="dataForm.dsDatabase" placeholder="数据库名称" maxlength="100" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="用户名" prop="dsUser">
            <el-input
              v-model="dataForm.dsUser"
              placeholder="用户名"
              maxlength="200" show-word-limit
              autocomplete="off"
              readonly
              onfocus="this.removeAttribute('readonly');"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="密码" :prop="dataForm.id ? '':'dsPass'" :key="dataForm.id ? 'notvalidate':'validate'">
            <el-input
              v-model="dataForm.dsPass"
              placeholder="密码"
              maxlength="200"
              show-word-limit
              type="password"
              show-password></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea"
        :autosize="{ minRows: 3, maxRows: 6}"
        v-model="dataForm.remark"
        placeholder="备注"
        maxlength="1000"
        show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :loading="isOnSubmit">取消</el-button>
      <el-button type="success" @click="testContainer()" :loading="formLoading || isOnSubmit">测试连接</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="formLoading || isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getById, saveOrUpdate, testContainer } from '@/api/hbi/dynamicdatasource.js'
import { validateIP, validatePort } from '@/utils/validate.js'
import { encryptDes } from "@/utils/des";
export default {
  data () {
    return {
      visible: false,
      isOnSubmit: false,
      formLoading: false,
      dataForm: {
        id: null,
        name: '',
        dsIp: '',
        dsPort: '',
        dsType: '',
        dsDatabase: '',
        dsUser: '',
        dsPass: '',
        version: '',
        deleteFlag: '',
        remark: ''
      },
      dataRule: {
        name: [{ required: true, message: '数据源名称，不可重复不能为空', trigger: 'blur' }],
        dsIp: [{ required: true, validator: validateIP.bind({name:'数据源IP'}), trigger: 'blur' }],
        dsPort: [{ required: true, validator: validatePort.bind({name:'数据源端口'}), trigger: 'blur' }],
        dsType: [{ required: true, message: '请选择数据库类型', trigger: ['blur','change'] }],
        dsDatabase: [{ required: true, message: '数据库名称不能为空', trigger: 'blur' }],
        dsUser: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
        dsPass: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
      }
    }
  },
  methods: {
    init (id) {
      this.dataForm.id = id
      this.visible = true
      this.isOnSubmit = false
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          this.formLoading = true
          getById(id).then(({ data }) => {
            if (data && data.code === 0) {
              let res = data.data
              for (let item in this.dataForm) {
                if (res[item] || res[item] === 0) {
                  this.dataForm[item] = res[item] + ''
                } else {
                  this.dataForm[item] = ''
                }
              }
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(() => {
            this.formLoading = false
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.isOnSubmit = true
          const data_ = JSON.parse(JSON.stringify(this.dataForm))
          // 密码加密
          data_.dsPass = encryptDes(data_.dsPass);
          saveOrUpdate(data_).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500
              })
              this.visible = false
              this.$emit('refreshDataList')
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(() => {
            this.isOnSubmit = false
          })
        }
      })
    },
    /** 测试连接 */
    testContainer() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.formLoading = true
          const params_ = {
            'id': this.dataForm.id,
            'dsType': this.dataForm.dsType,
            'dsIp': this.dataForm.dsIp,
            'dsPort': this.dataForm.dsPort,
            'dsDatabase': this.dataForm.dsDatabase,
            'dsUser': this.dataForm.dsUser,
            'dsPass': encryptDes(this.dataForm.dsPass)
          }
          testContainer(params_).then(({ data }) => {
            if (data && data.code === 0) {
              this.$alert('数据库链接测试通过', '提示', {
                confirmButtonText: '确定',
                type: 'success'
              });
            } else {
              this.$alert('数据库链接测试失败', '提示', {
                confirmButtonText: '确定',
                type: 'error'
              });
              //this.$message.error(data.msg)
            }
          }).catch((err) => {
            console.error(err)
          }).finally(() => {
            this.formLoading = false
          })
        }
      })
    }
  }
}
</script>
